<template>
  <modal-card title="Token call logs" cancel-text="Close">
    <call-logs-table :token-ref="tokenRef" />
  </modal-card>
</template>

<script>
export default {
  name: "TokenCallLogsModal",
  components: {
    "call-logs-table": () => import("@shared/tokens/_callLogsTable")
  },
  props: {
    tokenRef: {
      type: Object,
      required: true
    }
  }
};
</script>
